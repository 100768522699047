<template>
  <div class="floater">
    <label-preview
      :label="label"
      :previewEndpoint="previewEndpoint"
      :validate="validate"
    />
    <b-button
      variant="primary"
      :disabled="!canPrint"
      @click="printLabel"
    >
      Print
    </b-button>
  </div>
</template>

<script>
import {
  getLabelPrinter,
  validateLabel,
} from '@/helpers';

const LabelPreview = () => import('@/components/labels/LabelPreview.vue');

export default {
  name: 'LabelPreviewFloater',
  components: {
    LabelPreview,
  },
  props: {
    label: Object,
    validate: {
      type: Boolean,
      default: true,
    },
    printEndpoint: {
      type: String,
      default: '/label_print',
    },
    previewEndpoint: {
      type: String,
      default: '/label_preview',
    },
  },
  computed: {
    canPrint() {
      if (this.printBusy) return false;
      if (this.validate && !validateLabel(this.label)) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      printBusy: false,
    };
  },
  methods: {
    printLabel() {
      this.printBusy = true;
      this.$http
        .post(this.printEndpoint)
        .send({ printer_id: getLabelPrinter() })
        .send({ label: this.label })
        .then(() => {
          this.$emit('print');
        })
        .catch((err) => {
          this.$toast.error(`Failed to print: ${err.response.text}`);
        })
        .finally(() => {
          this.printBusy = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .floater {
    width: 100%;
    position: sticky;
    bottom: 15px;
    left: 0;
    display: grid;
    gap: 8px;
    grid-template-columns: max-content;
    justify-content: center;
  }

  @media (min-width: 980px) {
    .floater {
      margin-left: 650px;
      width: auto;
      justify-content: start;
    }
  }
</style>
